@import url("https://fonts.googleapis.com/css2?family=Space+Mono&display=swap");

/* Custom Properties */

:root {
  /* Colors */

  --clr-strong-cyan: hsl(172, 67%, 45%);
  --clr-dark-cyan: hsl(183, 100%, 15%);
  --clr-dark-grey-cyan: hsl(186, 14%, 43%);
  --clr-light-grey-cyan: hsl(185, 41%, 84%);
  --clr-white: hsl(0, 0%, 100%);

  /* Font */

  --fs-24: 24px;

  --ff: "Space Mono";

  --fw-400: 400;
  --fw-500: 500;
  --fw-700: 700;
}

/* Reset */

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
p {
  margin: 0;
}

body {
  display: flex;
  justify-content: center;

  align-items: center;
  min-height: 100vh;
  background-color: var(--clr-light-grey-cyan);
  font-family: var(--ff);
  color: var(--clr-dark-grey-cyan);
}

input,
button,
textarea,
select {
  font: inherit;
}

.container {
  background-color: var(--clr-white);
  display: flex;
  padding: 2rem;
  border-radius: 25px;
  margin: 4rem 0;
  max-width: 50rem;
  gap: 4rem;
}

input,
label {
  font-size: 1.1rem;
}

h2 {
  font-size: 0.9rem;
  letter-spacing: 1px;
}

p {
  font-size: 0.7em;
}

/* Utility Classes */

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

/* Colors */

.bg-white {
  background-color: var(--clr-white);
}

.bg-dark {
  background-color: var(--clr-dark-cyan);
}

.bg-light {
  background-color: var(--clr-light-grey-cyan);
}

.bg-cyan {
  background-color: var(--clr-strong-cyan);
}

.text-white {
  color: var(--clr-white);
}

.text-cyan {
  color: var(--clr-strong-cyan);
}

.text-dark {
  color: var(--clr-dark-cyan);
}

.text-light {
  color: var(--clr-light-grey-cyan);
}

/*Overall*/

.logo {
  margin-inline: auto;
  display: block;
  margin-top: 1rem;
}

/* Calculator */

input[type="text"] {
  margin: 0;
  border: none;
  background-color: hsl(40, 37%, 97%);
}

input[type="radio"] {
  display: none;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

input[type="radio"] + label {
  cursor: pointer;
  padding: 5px 10px;
  color: var(--clr-white);
  background-color: var(--clr-dark-cyan);
}

input[type="radio"]:checked + label {
  background-color: var(--clr-strong-cyan);
  color: var(--clr-dark-cyan);
}

input[type="radio"] + label:hover {
  background-color: var(--clr-strong-cyan);
}

input,
.tip {
  height: 3em;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 4px;
}

.number:hover {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.number:focus {
  outline: 2px solid var(--clr-strong-cyan);
}

.number-error {
  outline: 2px solid orange;
}

.input-background {
  background-repeat: no-repeat;
  background-position: 0.5rem center;
  padding-right: 0.5rem;
  text-align: right;
}

.calculator {
  flex: 1;
  gap: 2rem;
}

.error {
  color: orange;
  display: none;
}

.show-error .error {
  display: block;
}

.show-error input,
.show-error input:focus {
  outline: 2px solid orange;
}

/* Result */

.result {
  flex: 1;
  background-color: var(--clr-dark-cyan);
  border-radius: 15px;
  padding: 2rem;
}

.amount-container {
  margin-bottom: 3rem;
}

.calculator input[type="text"] {
  width: 100%;
}

.amount {
  font-size: 2.5em;
}

.btn {
  border: none;
  box-shadow: none;
  margin-top: 2rem;
  cursor: pointer;
  background-color: var(--clr-strong-cyan);
}

.btn:hover {
  background-color: var(--clr-dark-cyan);
  border: solid 1px var(--clr-strong-cyan);
  color: var(--clr-strong-cyan);
}

.btn-disabled {
  background-color: hsl(183, 100%, 22%);
  color: hsl(183, 100%, 15%);
  cursor: not-allowed;
}

.btn-disabled:hover {
  background-color: hsl(183, 100%, 22%);
  color: hsl(183, 100%, 15%);
  cursor: not-allowed;
  border: none;
}

.calculator h2,
.calculator p {
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 764px) {
  .container {
    flex-direction: column;
    margin: 1rem 0 0 0;
    gap: 2rem;
  }

  .amount-container {
    margin-bottom: 0;
  }

  .grid {
    grid-template-columns: 1fr 1fr;
  }
}
